* {
  box-sizing: border-box;
}
html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

::placeholder {
  opacity: 1 !important;
}

.MuiBreadcrumbs-ol {
  flex-wrap: nowrap !important;
}

.flex_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.flex_box_vertical {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.table_flex_box {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
}

.table_flex_box_vertical {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.search {
  padding: 25px 0px;
}
.top_nav {
  background-color: #dfeefd;
  padding: 40px 0px;
}
.nav_item {
  padding: 30px 25px;
  background-color: #fff;
  width: 100%;
  height: 117px;
  cursor: pointer;
}
.text_section {
  background-color: #fff;
  padding: 40px;
}
.noneTextDecor {
  text-decoration: none;
  color: #000;
}
.create_page {
  width: 100%;
  height: calc(100vh - 57px);
  background-color: #f1f2f3;
}
.sidebar {
  width: 333px;
  height: 100%;
  min-height: 100%;
  padding: 48px 36px;
  background-color: #ffffff;
}
.create_page .content {
  width: 100%;
  height: 100%;
  padding: 50px 32px;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.create_page .content::-webkit-scrollbar {
  display: none;
}
.create_page .content .content_wrapper {
  min-width: 1500px;
}
.create_page .content .paper {
  width: 100%;
  min-height: calc(100% - 53px);
  padding: 32px 40px;
  background: #ffffff;
  border: 1px solid #ececec;
  box-sizing: border-box;
  border-radius: 4px;
}
.dialog_close_icon {
  position: absolute;
  top: 20px;
  right: 24px;
  cursor: pointer;
}
.goBackArrow {
  cursor: pointer;
}
.unsplash {
  width: calc(50% - 22px);
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("./assets/images/unsplash.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.blue_cover {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #1058fb;
  opacity: 0.7;
}
.chevronDownIcon {
  width: 15px;
  height: 15px;
  position: absolute;
  right: 14px;
  top: 12px;
}
.header_logo {
  width: 33px;
  height: 33px;
  margin-right: 10px;
  border-radius: 50%;
  overflow: hidden;
  background-image: url("./assets/SVG/Logo_Stat.svg");
  background-size: cover;
  background-repeat: no-repeat;
}
.employee_ava {
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 50%;
  background-color: #76aad5;
}
.data_grid_wrapper {
  width: 100%;
  overflow: auto hidden;
  margin-bottom: 40px;
}
.data_grid_container {
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  border: 0.5px solid #757575;
}
.data_grid_column {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.data_table_header_cell,
.data_table__cell {
  max-width: 700px;
}
.data_table_header_cell p,
.data_table__cell p {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.data_table_header_cell {
  min-height: 59px;
  padding: 26px 32px 8px 32px;
}
.data_table__cell {
  cursor: pointer;
  min-height: 41px;
  padding: 8px 32px;
}
.jodit_content_wrapper {
  overflow: auto hidden;
}
.metadata_item span {
  color: #757575;
}
.metadata_item p {
  color: #000;
}

@media (max-width: 1200px) {
  .search {
    padding: 25px 40px;
  }
}

/* @media (min-width: 1200px) {
  .MuiContainer-maxWidthLg {
    max-width: 1095px !important;
  }
} */

@media (max-width: 960px) {
  .search .top_nav,
  .text_section {
    padding: 30px 20px;
  }
}
